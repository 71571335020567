<template>
  <section class="grid place-items-center bg-green-800 py-12 md:py-16">
    <img
      src="https://assets.aussiebroadband.com.au/web/images/misc/abb-og-image.png"
      alt="Aussie Broadband"
    />
  </section>
</template>

<script setup lang="ts"></script>
